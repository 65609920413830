import * as actions from "../actions/actionTypes";
import produce from "immer";

const initialState = {
    plans: [],
    error: false,
};

const planReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // Get all Plans
        case actions.GET_ALL_PLANS_START:
            return { ...state, error: false };

        case actions.GET_ALL_PLANS_SUCCESS:
            return { ...state, plans: payload };

        case actions.GET_ALL_PLANS_FAIL:
            return { ...state, error: true };

        // Add New Plan
        case actions.ADD_PLAN_START:
            return { ...state, error: false };

        case actions.ADD_PLAN_SUCCESS:
            const newState1 = produce(state, draftState => {
                draftState.plans.unshift(payload)
            })
            return newState1;

        case actions.ADD_PLAN_FAIL:
            return { ...state, error: true };

        // Update Plan
        case actions.UPDATE_PLAN_START:
            return { ...state, error: false };

        case actions.UPDATE_PLAN_SUCCESS:
            const index1 = state.plans.findIndex(item => item._id === payload._id)
            const newState2 = produce(state, draftState => {
                draftState.plans[index1] = payload;
            })
            return newState2;

        case actions.UPDATE_PLAN_FAIL:
            return { ...state, error: true };

        // Remove Plan
        case actions.REMOVE_PLAN_START:
            return { ...state, error: false };

        case actions.REMOVE_PLAN_SUCCESS:
            return { ...state, plans: state.plans.filter(item => item._id !== payload) };

        case actions.REMOVE_PLAN_FAIL:
            return { ...state, error: true };

        default:
            return state;
    }
};

export default planReducer;
