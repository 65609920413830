import * as actions from "../actions/actionTypes";
import produce from "immer";

const initialState = {
    chapters: [],
    error: false,
};

const chapterReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // GET ALL Chapters
        case actions.GET_ALL_CHAPTERS_START:
            return { ...state, error: false };

        case actions.GET_ALL_CHAPTERS_SUCCESS:
            return { ...state, chapters: payload };

        case actions.GET_ALL_CHAPTERS_FAIL:
            return { ...state, error: true };

        // Add New Chapter
        case actions.ADD_CHAPTER_START:
            return { ...state, error: false };

        case actions.ADD_CHAPTER_SUCCESS:
            const newState1 = produce(state, draftState => {
                draftState.chapters.unshift(payload)
            })
            return newState1;

        case actions.ADD_CHAPTER_FAIL:
            return { ...state, error: true };

        // Update Chapter
        case actions.UPDATE_CHAPTER_START:
            return { ...state, error: false };

        case actions.UPDATE_CHAPTER_SUCCESS:
            const index1 = state.chapters.findIndex(item => item._id === payload._id)
            const newState2 = produce(state, draftState => {
                draftState.chapters[index1] = payload;
            })
            return newState2;

        case actions.UPDATE_CHAPTER_FAIL:
            return { ...state, error: true };

        // Remove Chapter
        case actions.REMOVE_CHAPTER_START:
            return { ...state, error: false };

        case actions.REMOVE_CHAPTER_SUCCESS:
            return { ...state, chapters: state.chapters.filter(item => item._id !== payload) };

        case actions.REMOVE_CHAPTER_FAIL:
            return { ...state, error: true };

        case actions.UPDATE_PREMIUM_STATUS_REQUEST:
            return {
                ...state, error: false
            };
        case actions.UPDATE_PREMIUM_STATUS_SUCCESS:
            let index3 = state.chapters.findIndex((item) => item._id === payload._id);
            const newState3 = produce(state, draft => {
                draft.chapters[index3].premium = payload.premium
            })
            return newState3
        case actions.UPDATE_PREMIUM_STATUS_FAILURE:
            return {
                ...state, error: true
            };

        default:
            return state;
    }
};

export default chapterReducer;
