import * as actions from "../actions/actionTypes";
import produce from "immer";

const initialState = {
    doubts: [],
    error: false,
    pagination: {
        pagesCount: 0,
        doubtCount: 0
    }
};

const doubtReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // Get all doubts
        case actions.GET_ALL_DOUBTS_START:
            return { ...state, error: false };

        case actions.GET_ALL_DOUBTS_SUCCESS:
            return { ...state, doubts: payload.data, pagination: payload.pagination };

        case actions.GET_ALL_DOUBTS_FAIL:
            return { ...state, error: true };

        // Reply Question
        case actions.REPLY_DOUBT_START:
            return { ...state, error: false };

        case actions.REPLY_DOUBT_SUCCESS:
            const index1 = state.doubts.findIndex(item => item._id === payload._id)
            const newState2 = produce(state, draftState => {
                draftState.doubts[index1] = payload;
            })
            return newState2;

        case actions.REPLY_DOUBT_FAIL:
            return { ...state, error: true };

        // Remove Doubt
        case actions.REMOVE_DOUBT_START:
            return { ...state, error: false };

        case actions.REMOVE_DOUBT_SUCCESS:
            return { ...state, doubts: state.doubts.filter(item => item._id !== payload) };

        case actions.REMOVE_DOUBT_FAIL:
            return { ...state, error: true };

        default:
            return state;
    }
};

export default doubtReducer;
