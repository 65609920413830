import * as actions from "../actions/actionTypes";
import produce from "immer";

const initialState = {
    students: [],
    error: false,
    pagination: {
        pagesCount: 0,
        memberCount: 0
    }
};

const studentReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // GET ALL STUDENTS
        case actions.GET_ALL_STUDENTS_START:
            return { ...state, error: false };

        case actions.GET_ALL_STUDENTS_SUCCESS:
            return { ...state, students: payload.data, pagination: payload.pagination };

        case actions.GET_ALL_STUDENTS_FAIL:
            return { ...state, error: true };

        // Delete Student
        case actions.REMOVE_STUDENT_START:
            return { ...state, error: false };

        case actions.REMOVE_STUDENT_SUCCESS:
            return { ...state, students: state.students.filter(student => student._id !== payload) };

        case actions.REMOVE_STUDENT_FAIL:
            return { ...state, error: true };

        // Update Student
        case actions.UPDATE_STUDENT_START:
            return { ...state, error: false };

        case actions.UPDATE_STUDENT_SUCCESS:
            const index1 = state.students.findIndex(student => student._id === payload._id)
            const newState1 = produce(state, draftState => {
                draftState.students[index1] = payload;
            })
            return newState1;

        case actions.UPDATE_STUDENT_FAIL:
            return { ...state, error: true };

        case actions.UPDATE_VERIFIED_STATUS_REQUEST:
            return {
                ...state,
            };
        case actions.UPDATE_VERIFIED_STATUS_SUCCESS:
            let index2 = state.students.findIndex((item) => item._id === payload._id);
            const newState2 = produce(state, draft => {
                draft.students[index2].is_verified = payload.is_verified
            })
            return newState2
        case actions.UPDATE_VERIFIED_STATUS_FAILURE:
            return {
                ...state,
            };

        case actions.UPDATE_ACTIVE_STATUS_REQUEST:
            return {
                ...state,
            };
        case actions.UPDATE_ACTIVE_STATUS_SUCCESS:
            let index3 = state.students.findIndex((item) => item._id === payload._id);
            const newState3 = produce(state, draft => {
                draft.students[index3].active = payload.active
            })
            return newState3;

        case actions.UPDATE_ACTIVE_STATUS_FAILURE:
            return {
                ...state,
            };

        default:
            return state;
    }
};

export default studentReducer;
