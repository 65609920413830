import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";

let store = "";
if (process.env.REACT_APP_ENVIRONMENT !== 'Local') {
  store = createStore(
    rootReducer,
    compose(
      applyMiddleware(thunk),
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  );

} else {
  store = createStore(
    rootReducer,
    compose(
      applyMiddleware(thunk),

    )
  );

}


export default store;
