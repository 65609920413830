import * as actions from "../actions/actionTypes";
import produce from "immer";

const initialState = {
    boards: [],
    error: false,
};

const boardReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // GET ALL BOARDS
        case actions.GET_ALL_BOARDS_START:
            return { ...state, error: false };

        case actions.GET_ALL_BOARDS_SUCCESS:
            return { ...state, boards: payload };

        case actions.GET_ALL_BOARDS_FAIL:
            return { ...state, error: true };

        // Add New BOARD
        case actions.ADD_BOARD_START:
            return { ...state, error: false };

        case actions.ADD_BOARD_SUCCESS:
            const newState1 = produce(state, draftState => {
                draftState.boards.unshift(payload)
            })
            return newState1;

        case actions.ADD_BOARD_FAIL:
            return { ...state, error: true };

        // Update Board
        case actions.UPDATE_BOARD_START:
            return { ...state, error: false };

        case actions.UPDATE_BOARD_SUCCESS:
            const index1 = state.boards.findIndex(board => board._id === payload._id)
            const newState2 = produce(state, draftState => {
                draftState.boards[index1] = payload;
            })
            return newState2;

        case actions.UPDATE_BOARD_FAIL:
            return { ...state, error: true };

        // Remove Board
        case actions.REMOVE_BOARD_START:
            return { ...state, error: false };

        case actions.REMOVE_BOARD_SUCCESS:
            return { ...state, boards: state.boards.filter(board => board._id !== payload) };

        case actions.REMOVE_BOARD_FAIL:
            return { ...state, error: true };

        default:
            return state;
    }
};

export default boardReducer;
