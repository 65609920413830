import * as actions from "../actions/actionTypes";
import produce from "immer";

const initialState = {
    classes: [],
    error: false,
};

const classReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // GET ALL Classes
        case actions.GET_ALL_CLASSES_START:
            return { ...state, error: false };

        case actions.GET_ALL_CLASSES_SUCCESS:
            return { ...state, classes: payload };

        case actions.GET_ALL_CLASSES_FAIL:
            return { ...state, error: true };

        // Add New Class
        case actions.ADD_CLASS_START:
            return { ...state, error: false };

        case actions.ADD_CLASS_SUCCESS:
            const newState1 = produce(state, draftState => {
                draftState.classes.unshift(payload)
            })
            return newState1;

        case actions.ADD_CLASS_FAIL:
            return { ...state, error: true };

        // Update Class
        case actions.UPDATE_CLASS_START:
            return { ...state, error: false };

        case actions.UPDATE_CLASS_SUCCESS:
            const index1 = state.classes.findIndex(item => item._id === payload._id)
            const newState2 = produce(state, draftState => {
                draftState.classes[index1] = payload;
            })
            return newState2;

        case actions.UPDATE_CLASS_FAIL:
            return { ...state, error: true };

        // Remove Class
        case actions.REMOVE_CLASS_START:
            return { ...state, error: false };

        case actions.REMOVE_CLASS_SUCCESS:
            return { ...state, classes: state.classes.filter(item => item._id !== payload) };

        case actions.REMOVE_CLASS_FAIL:
            return { ...state, error: true };

        default:
            return state;
    }
};

export default classReducer;
