import "./App.css";
import { useLocation } from "react-router-dom";
import Navbar from "./layouts/Navbar";
import Sidebar from "./layouts/Sidebar";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getAllBoards } from "redux/actions/boardAction";
import RoutesData from "routes";
import { isEmpty } from "lodash";
import Cookies from "js-cookie";
import { SESSION_TOKEN } from "utils/constants";
function App() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  let auth = Cookies.get(SESSION_TOKEN);
  let flag = !isEmpty(auth);

  useEffect(() => {
    dispatch(getAllBoards());
  }, []);

  return (
    <>
      <div>
        {flag && <Sidebar />}
        <div className={`${flag && "md:pl-64"} flex flex-col flex-1`}>
          {flag && <Navbar />}

          <RoutesData />
        </div>
      </div>
      <ToastContainer autoClose={3000} position="top-center" />
    </>
  );
}

export default App;
