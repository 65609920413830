import Cookies from "js-cookie";
import * as actions from "../actions/actionTypes";
import { SESSION_USERINFO } from "utils/constants";
let userInfo = Cookies.get(SESSION_USERINFO);
const user = userInfo ? JSON.parse(userInfo) : {};

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // SIGNUP User
    case actions.SIGNUP_START:
      return { ...state };
    case actions.SIGNUP_SUCCESS:
      return { ...state, isLoggedIn: false };
    case actions.SIGNUP_FAIL:
      return { ...state, isLoggedIn: false };
    // LOGIN/LOGOUT user
    case actions.LOGIN_START:
      return { ...state };
    case actions.LOGIN_SUCCESS:
      return { ...state, isLoggedIn: true, user: payload };
    case actions.LOGIN_FAIL:
      return { ...state, isLoggedIn: false, user: null };
    case actions.LOGOUT:
      return { ...state, isLoggedIn: false, user: null };
    default:
      return state;
  }
};

export default authReducer;
