export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const GET_ALL_STUDENTS_START = "GET_ALL_STUDENTS_START";
export const GET_ALL_STUDENTS_SUCCESS = "GET_ALL_STUDENTS_SUCCESS";
export const GET_ALL_STUDENTS_FAIL = "GET_ALL_STUDENTS_FAIL";

export const GET_ONE_STUDENTS_START = "GET_ONE_STUDENTS_START";
export const GET_ONE_STUDENTS_SUCCESS = "GET_ONE_STUDENTS_SUCCESS";
export const GET_ONE_STUDENTS_FAIL = "GET_ONE_STUDENTS_FAIL";

export const UPDATE_STUDENT_START = "UPDATE_STUDENT_START";
export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS";
export const UPDATE_STUDENT_FAIL = "UPDATE_STUDENT_FAIL";

export const REMOVE_STUDENT_START = "REMOVE_STUDENT_START";
export const REMOVE_STUDENT_SUCCESS = "REMOVE_STUDENT_SUCCESS";
export const REMOVE_STUDENT_FAIL = "REMOVE_STUDENT_FAIL";

export const UPDATE_VERIFIED_STATUS_REQUEST = "UPDATE_VERIFIED_STATUS_REQUEST";
export const UPDATE_VERIFIED_STATUS_SUCCESS = "UPDATE_VERIFIED_STATUS_SUCCESS";
export const UPDATE_VERIFIED_STATUS_FAILURE = "UPDATE_VERIFIED_STATUS_FAILURE";

export const UPDATE_ACTIVE_STATUS_REQUEST = "UPDATE_ACTIVE_STATUS_REQUEST";
export const UPDATE_ACTIVE_STATUS_SUCCESS = "UPDATE_ACTIVE_STATUS_SUCCESS";
export const UPDATE_ACTIVE_STATUS_FAILURE = "UPDATE_ACTIVE_STATUS_FAILURE";

export const GET_ALL_BOARDS_START = "GET_ALL_BOARDS_START";
export const GET_ALL_BOARDS_SUCCESS = "GET_ALL_BOARDS_SUCCESS";
export const GET_ALL_BOARDS_FAIL = "GET_ALL_BOARDS_FAIL";

export const ADD_BOARD_START = "ADD_BOARD_START";
export const ADD_BOARD_SUCCESS = "ADD_BOARD_SUCCESS";
export const ADD_BOARD_FAIL = "ADD_BOARD_FAIL";

export const UPDATE_BOARD_START = "UPDATE_BOARD_START";
export const UPDATE_BOARD_SUCCESS = "UPDATE_BOARD_SUCCESS";
export const UPDATE_BOARD_FAIL = "UPDATE_BOARD_FAIL";

export const REMOVE_BOARD_START = "REMOVE_BOARD_START";
export const REMOVE_BOARD_SUCCESS = "REMOVE_BOARD_SUCCESS";
export const REMOVE_BOARD_FAIL = "REMOVE_BOARD_FAIL";

export const GET_ALL_CLASSES_START = "GET_ALL_CLASSES_START";
export const GET_ALL_CLASSES_SUCCESS = "GET_ALL_CLASSES_SUCCESS";
export const GET_ALL_CLASSES_FAIL = "GET_ALL_CLASSES_FAIL";

export const ADD_CLASS_START = "ADD_CLASS_START";
export const ADD_CLASS_SUCCESS = "ADD_CLASS_SUCCESS";
export const ADD_CLASS_FAIL = "ADD_CLASS_FAIL";

export const UPDATE_CLASS_START = "UPDATE_CLASS_START";
export const UPDATE_CLASS_SUCCESS = "UPDATE_CLASS_SUCCESS";
export const UPDATE_CLASS_FAIL = "UPDATE_CLASS_FAIL";

export const REMOVE_CLASS_START = "REMOVE_CLASS_START";
export const REMOVE_CLASS_SUCCESS = "REMOVE_CLASS_SUCCESS";
export const REMOVE_CLASS_FAIL = "REMOVE_CLASS_FAIL";

export const GET_ALL_SUBJECTS_START = "GET_ALL_SUBJECTS_START";
export const GET_ALL_SUBJECTS_SUCCESS = "GET_ALL_SUBJECTS_SUCCESS";
export const GET_ALL_SUBJECTS_FAIL = "GET_ALL_SUBJECTS_FAIL";

export const ADD_SUBJECT_START = "ADD_SUBJECT_START";
export const ADD_SUBJECT_SUCCESS = "ADD_SUBJECT_SUCCESS";
export const ADD_SUBJECT_FAIL = "ADD_SUBJECT_FAIL";

export const UPDATE_SUBJECT_START = "UPDATE_SUBJECT_START";
export const UPDATE_SUBJECT_SUCCESS = "UPDATE_SUBJECT_SUCCESS";
export const UPDATE_SUBJECT_FAIL = "UPDATE_SUBJECT_FAIL";

export const REMOVE_SUBJECT_START = "REMOVE_SUBJECT_START";
export const REMOVE_SUBJECT_SUCCESS = "REMOVE_SUBJECT_SUCCESS";
export const REMOVE_SUBJECT_FAIL = "REMOVE_SUBJECT_FAIL";

export const GET_ALL_CHAPTERS_START = "GET_ALL_CHAPTERS_START";
export const GET_ALL_CHAPTERS_SUCCESS = "GET_ALL_CHAPTERS_SUCCESS";
export const GET_ALL_CHAPTERS_FAIL = "GET_ALL_CHAPTERS_FAIL";

export const ADD_CHAPTER_START = "ADD_CHAPTER_START";
export const ADD_CHAPTER_SUCCESS = "ADD_CHAPTER_SUCCESS";
export const ADD_CHAPTER_FAIL = "ADD_CHAPTER_FAIL";

export const UPDATE_CHAPTER_START = "UPDATE_CHAPTER_START";
export const UPDATE_CHAPTER_SUCCESS = "UPDATE_CHAPTER_SUCCESS";
export const UPDATE_CHAPTER_FAIL = "UPDATE_CHAPTER_FAIL";

export const REMOVE_CHAPTER_START = "REMOVE_CHAPTER_START";
export const REMOVE_CHAPTER_SUCCESS = "REMOVE_CHAPTER_SUCCESS";
export const REMOVE_CHAPTER_FAIL = "REMOVE_CHAPTER_FAIL";

export const UPDATE_PREMIUM_STATUS_REQUEST = "UPDATE_PREMIUM_STATUS_REQUEST";
export const UPDATE_PREMIUM_STATUS_SUCCESS = "UPDATE_PREMIUM_STATUS_SUCCESS";
export const UPDATE_PREMIUM_STATUS_FAILURE = "UPDATE_PREMIUM_STATUS_FAILURE";

export const GET_DASHBOARD_COUNTS_START = "GET_DASHBOARD_COUNTS_START";
export const GET_DASHBOARD_COUNTS_SUCCESS = "GET_DASHBOARD_COUNTS_SUCCESS";
export const GET_DASHBOARD_COUNTS_FAIL = "GET_DASHBOARD_COUNTS_FAIL";

export const GET_ALL_STREAMS_START = "GET_ALL_STREAMS_START";
export const GET_ALL_STREAMS_SUCCESS = "GET_ALL_STREAMS_SUCCESS";
export const GET_ALL_STREAMS_FAIL = "GET_ALL_STREAMS_FAIL";

export const ADD_STREAM_START = "ADD_STREAM_START";
export const ADD_STREAM_SUCCESS = "ADD_STREAM_SUCCESS";
export const ADD_STREAM_FAIL = "ADD_STREAM_FAIL";

export const UPDATE_STREAM_START = "UPDATE_STREAM_START";
export const UPDATE_STREAM_SUCCESS = "UPDATE_STREAM_SUCCESS";
export const UPDATE_STREAM_FAIL = "UPDATE_STREAM_FAIL";

export const REMOVE_STREAM_START = "REMOVE_STREAM_START";
export const REMOVE_STREAM_SUCCESS = "REMOVE_STREAM_SUCCESS";
export const REMOVE_STREAM_FAIL = "REMOVE_STREAM_FAIL";

export const GET_ALL_CONTACTS_START = "GET_ALL_CONTACTS_START";
export const GET_ALL_CONTACTS_SUCCESS = "GET_ALL_CONTACTS_SUCCESS";
export const GET_ALL_CONTACTS_FAIL = "GET_ALL_CONTACTS_FAIL";

export const REMOVE_CONTACT_START = "REMOVE_CONTACT_START";
export const REMOVE_CONTACT_SUCCESS = "REMOVE_CONTACT_SUCCESS";
export const REMOVE_CONTACT_FAIL = "REMOVE_CONTACT_FAIL";

export const GET_ALL_DOUBTS_START = "GET_ALL_DOUBTS_START";
export const GET_ALL_DOUBTS_SUCCESS = "GET_ALL_DOUBTS_SUCCESS";
export const GET_ALL_DOUBTS_FAIL = "GET_ALL_DOUBTS_FAIL";

export const REPLY_DOUBT_START = "REPLY_DOUBT_START";
export const REPLY_DOUBT_SUCCESS = "REPLY_DOUBT_SUCCESS";
export const REPLY_DOUBT_FAIL = "REPLY_DOUBT_FAIL";

export const REMOVE_DOUBT_START = "REMOVE_DOUBT_START";
export const REMOVE_DOUBT_SUCCESS = "REMOVE_DOUBT_SUCCESS";
export const REMOVE_DOUBT_FAIL = "REMOVE_DOUBT_FAIL";

export const GET_ALL_PLANS_START = "GET_ALL_PLANS_START";
export const GET_ALL_PLANS_SUCCESS = "GET_ALL_PLANS_SUCCESS";
export const GET_ALL_PLANS_FAIL = "GET_ALL_PLANS_FAIL";

export const ADD_PLAN_START = "ADD_PLAN_START";
export const ADD_PLAN_SUCCESS = "ADD_PLAN_SUCCESS";
export const ADD_PLAN_FAIL = "ADD_PLAN_FAIL";

export const UPDATE_PLAN_START = "UPDATE_PLAN_START";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const UPDATE_PLAN_FAIL = "UPDATE_PLAN_FAIL";

export const REMOVE_PLAN_START = "REMOVE_PLAN_START";
export const REMOVE_PLAN_SUCCESS = "REMOVE_PLAN_SUCCESS";
export const REMOVE_PLAN_FAIL = "REMOVE_PLAN_FAIL";

export const GET_ALL_QUIZ_START = "GET_ALL_QUIZ_START";
export const GET_ALL_QUIZ_SUCCESS = "GET_ALL_QUIZ_SUCCESS";
export const GET_ALL_QUIZ_FAIL = "GET_ALL_QUIZ_FAIL";

export const ADD_QUIZ_START = "ADD_QUIZ_START";
export const ADD_QUIZ_SUCCESS = "ADD_QUIZ_SUCCESS";
export const ADD_QUIZ_FAIL = "ADD_QUIZ_FAIL";

export const UPDATE_QUIZ_START = "UPDATE_QUIZ_START";
export const UPDATE_QUIZ_SUCCESS = "UPDATE_QUIZ_SUCCESS";
export const UPDATE_QUIZ_FAIL = "UPDATE_QUIZ_FAIL";

export const REMOVE_QUIZ_START = "REMOVE_QUIZ_START";
export const REMOVE_QUIZ_SUCCESS = "REMOVE_QUIZ_SUCCESS";
export const REMOVE_QUIZ_FAIL = "REMOVE_QUIZ_FAIL";
