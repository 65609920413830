import Loader from "common/Loader";
import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import configureRoute from "./routes";
import Cookies from "js-cookie";
import { SESSION_TOKEN } from "utils/constants";
import { isEmpty } from "lodash";
// const Login = lazy(() => import("pages/authentication/Login"));

const RoutesData = () => {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          {configureRoute.map((item, key) => {
            // console.log({ item });
            if (item.private) {
              let auth = Cookies.get(SESSION_TOKEN);
              if (!isEmpty(auth)) {
                return (
                  <Route key={key} path={item.path} element={item.element} />
                );
              } else {
                return (
                  <Route key={key} exact element={<PrivateRoute />}>
                    <Route key={key} path={item.path} element={item.element} />
                  </Route>
                );
              }
            } else {
              return (
                <Route key={key} path={item.path} element={item.element} />
              );
            }
            // return item.private ? (
            //   <Route key={key} exact element={<PrivateRoute />}>
            //     <Route key={key} path={item.path} element={item.element} />
            //   </Route>
            // ) : (
            //   <Route key={key} exact element={<PublicRoute />}>
            //     <Route key={key} path={item.path} element={item.element} />
            //   </Route>
            // );
          })}
        </Routes>
      </Suspense>
    </>
  );
};

export default RoutesData;
