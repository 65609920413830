import { lazy } from "react";

const Login = lazy(() => import("pages/authentication/Login"));
const SignUp = lazy(() => import("pages/authentication/Signup"));
const ForgotPassword = lazy(() =>
  import("pages/authentication/ForgotPassword")
);
const Dashboard = lazy(() => import("pages/Dashboard"));
const StudentsView = lazy(() => import("pages/students/StudentsView"));
const ContactsView = lazy(() => import("pages/contacts/ContactsView"));
const DoubtsView = lazy(() => import("pages/doubts/DoubtsView"));
const AllPlans = lazy(() => import("pages/plans/AllPlans"));
const ReplyDoubt = lazy(() => import("pages/doubts/ReplyDoubt"));
const AllClasses = lazy(() => import("pages/classes/AllClasses"));
const AllStreams = lazy(() => import("pages/streams/AllStreams"));
const AllBoards = lazy(() => import("pages/boards/AllBoards"));
const AllSubjects = lazy(() => import("pages/subjects/AllSubjects"));
const AllChapters = lazy(() => import("pages/chapters/AllChapters"));
const Lectures = lazy(() => import("pages/chapters/lectures/Lectures"));
const Experiences = lazy(() =>
  import("pages/chapters/experiences/Experiences")
);
const Notes = lazy(() => import("pages/chapters/notes/Notes"));
const Worksheets = lazy(() => import("pages/chapters/worksheets/Worksheets"));
const ArtAndCrafts = lazy(() =>
  import("pages/chapters/art-n-crafts/ArtAndCrafts")
);
const Quiz = lazy(() => import("pages/chapters/quiz/Quiz"));
const Questions = lazy(() => import("pages/chapters/quiz/Questions/Questions"));
const ChapterDetailsView = lazy(() =>
  import("pages/chapters/ChapterDetailsView")
);
const PageNotFound = lazy(() => import("pages/PageNotFound"));

const configureRoute = [
  {
    path: "/login",
    element: <Login />,
    private: false,
  },
  //     {
  //         path: "/signup",
  //         element: <SignUp />,
  //         private: false,
  //     },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    private: false,
  },
  {
    path: "/",
    element: <Dashboard />,
    private: true,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    private: true,
  },
  {
    path: "/students",
    element: <StudentsView />,
    private: true,
  },
  {
    path: "/contacts",
    element: <ContactsView />,
    private: true,
  },
  {
    path: "/plans",
    element: <AllPlans />,
    private: true,
  },
  {
    path: "/doubts",
    element: <DoubtsView />,
    private: true,
  },
  {
    path: "/doubt/answer",
    element: <ReplyDoubt />,
    private: true,
  },
  ,
  {
    path: "/classes",
    element: <AllClasses />,
    private: true,
  },
  {
    path: "/classes/board/:id",
    element: <AllClasses />,
    private: true,
  },
  {
    path: "/streams",
    element: <AllStreams />,
    private: true,
  },
  {
    path: "/streams/class/:id",
    element: <AllStreams />,
    private: true,
  },
  {
    path: "/boards",
    element: <AllBoards />,
    private: true,
  },
  {
    path: "/subjects",
    element: <AllSubjects />,
    private: true,
  },
  {
    path: "/subjects/stream/:id",
    element: <AllSubjects />,
    private: true,
  },
  {
    path: "/chapters",
    element: <AllChapters />,
    private: true,
  },
  {
    path: "/chapters/subject/:id",
    element: <AllChapters />,
    private: true,
  },
  {
    path: "/chapterDetailsView/:id",
    element: <ChapterDetailsView />,
    private: true,
  },
  {
    path: "/lectures/chapter/:id",
    element: <Lectures />,
    private: false,
  },
  {
    path: "/experiences/chapter/:id",
    element: <Experiences />,
    private: true,
  },
  {
    path: "/notes/chapter/:id",
    element: <Notes />,
    private: true,
  },
  {
    path: "/worksheets/chapter/:id",
    element: <Worksheets />,
    private: true,
  },
  {
    path: "/art&craft/chapter/:id",
    element: <ArtAndCrafts />,
    private: true,
  },
  {
    path: "/quiz/chapter/:id",
    element: <Quiz />,
    private: true,
  },
  {
    path: "*",
    element: <PageNotFound />,
    private: true,
  },
  {
    path: "/questions/quiz/:id",
    element: <Questions />,
    private: true,
  },
];

export default configureRoute;
