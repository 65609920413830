import * as actions from "./actionTypes";
import axiosClient from "utils/axiosClient";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { SESSION_TOKEN, SESSION_USERINFO } from "utils/constants";

// export const signup = (payload) => async (dispatch) => {
//   dispatch({
//     type: actions.SIGNUP_START,
//   });

//   let url = `${process.env.REACT_APP_BASE_URL}api/admin/sign-up`;
//   try {
//     const res = await axiosClient.post(url, payload.formData, {
//       headers: {
//         "Content-type": "application/json",
//       },
//     });
//     console.log(res);
//     dispatch({
//       type: actions.SIGNUP_SUCCESS,
//       payload: res?.data,
//     });
//     payload.callback();
//     setTimeout(
//       () => toast.success("Admin created successfully, Please Login"),
//       100
//     );
//   } catch (error) {
//     console.log(error);
//     dispatch({
//       type: actions.SIGNUP_FAIL,
//     });
//     // if (error.response?.data.message === 'User already exists') {
//     //     toast.error("Email address is already in use!")
//     // } else {
//     toast.error("Something went wrong!");
//     // }
//   }
// };

export const login = (payload) => async (dispatch) => {
  dispatch({
    type: actions.LOGIN_START,
  });

  let url = `${process.env.REACT_APP_BASE_URL}api/admin/login`;
  try {
    const res = await axiosClient.post(url, payload.formData, {
      headers: {
        "Content-type": "application/json",
      },
    });
    console.log(res?.data);
    if (res?.data.message === "Admin logged in successfully") {
      dispatch({
        type: actions.LOGIN_SUCCESS,
        payload: res?.data,
      });
      Cookies.set(SESSION_TOKEN, res?.data.token);
      Cookies.set(SESSION_USERINFO, JSON.stringify(res?.data?.data));
      //   localStorage.setItem("eshum|token", res?.data.token);
      //   localStorage.setItem("eshum|userInfo", JSON.stringify(res?.data?.data));
      payload.callback();
      setTimeout(
        () =>
          toast.success(
            `Welcome ${res?.data?.data.name}, You have successfully logged in!`
          ),
        100
      );
    } else if (res?.data.message === "Password do not match") {
      toast.warn("The password that you've entered is incorrect!");
    }
  } catch (error) {
    dispatch({
      type: actions.LOGIN_FAIL,
    });
    if (error.response?.data.message === "Admin not found") {
      toast.error("Couldn't' find your account, Please check the credentials!");
    } else {
      toast.error("Something went wrong");
    }
  }
};

export const logout = (payload) => (dispatch) => {
  Cookies.remove(SESSION_TOKEN);
  Cookies.remove(SESSION_USERINFO);
  //   localStorage.removeItem("eshum|token");
  //   localStorage.removeItem("eshum|userInfo");
  dispatch({ type: actions.LOGOUT });
  payload.callback();
  setTimeout(() => {
    toast.success("You have successfully logged out!");
  }, 500);
};
