import * as actions from "./actionTypes";
import axiosClient from "utils/axiosClient";
import { toast } from "react-toastify";

export const getAllBoards = () => async (dispatch) => {
  dispatch({
    type: actions.GET_ALL_BOARDS_START,
  });

  try {
    const res = await axiosClient.get(
      `${process.env.REACT_APP_BASE_URL}api/board`
    );
    // console.log(res)
    dispatch({
      type: actions.GET_ALL_BOARDS_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: actions.GET_ALL_BOARDS_FAIL,
    });
  }
};

export const addBoard =
  ({ formData, callback }) =>
  async (dispatch) => {
    dispatch({
      type: actions.ADD_BOARD_START,
    });

    try {
      const res = await axiosClient.post(
        `${process.env.REACT_APP_BASE_URL}api/board`,
        formData
      );
      dispatch({
        type: actions.ADD_BOARD_SUCCESS,
        payload: res.data.data,
      });
      toast.success("Board created Successfully");
      return callback();
    } catch (e) {
      dispatch({
        type: actions.ADD_BOARD_FAIL,
      });
      toast.error("Something went wrong !");
    }
  };

export const updateBoard =
  ({ formData, id, callback }) =>
  async (dispatch) => {
    dispatch({
      type: actions.UPDATE_BOARD_START,
    });

    try {
      const res = await axiosClient.patch(
        `${process.env.REACT_APP_BASE_URL}api/board/${id}`,
        formData
      );
      if (res.status === 200) {
        dispatch({
          type: actions.UPDATE_BOARD_SUCCESS,
          payload: res.data.data,
        });
        toast.success("Board updated Successfully");
      }
      return callback();
    } catch (e) {
      dispatch({
        type: actions.UPDATE_BOARD_FAIL,
      });
      toast.error("Something went wrong !");
    }
  };
export const removeBoard =
  ({ deleteId, callback }) =>
  async (dispatch) => {
    dispatch({
      type: actions.REMOVE_BOARD_START,
    });
    try {
      const res = await axiosClient.delete(
        `${process.env.REACT_APP_BASE_URL}api/board/${deleteId}`
      );
      dispatch({
        type: actions.REMOVE_BOARD_SUCCESS,
        payload: deleteId,
      });
      toast.success("Board deleted Successfully");
      callback();
    } catch (e) {
      dispatch({
        type: actions.REMOVE_BOARD_FAIL,
      });
      toast.error("Something went wrong !");
    }
  };
