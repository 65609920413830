import Cookies from "js-cookie";
import { SESSION_TOKEN } from "./constants";
import axios from "axios";

const admintoken = Cookies.get(SESSION_TOKEN);
const axiosClient = axios.create({
  baseURL: "", // Replace with your API base URL
  //   timeout: 5000, // Adjust the timeout as needed
  headers: {
    admintoken,
    // "Content-Type": "application/json",
    "Content-Type": "multipart/form-data",
  },
});

export default axiosClient;
