import * as actions from "../actions/actionTypes";
import produce from "immer";

const initialState = {
    subjects: [],
    error: false,
};

const subjectReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // GET ALL Subjects
        case actions.GET_ALL_SUBJECTS_START:
            return { ...state, error: false };

        case actions.GET_ALL_SUBJECTS_SUCCESS:
            return { ...state, subjects: payload };

        case actions.GET_ALL_SUBJECTS_FAIL:
            return { ...state, error: true };

        // Add New Subject
        case actions.ADD_SUBJECT_START:
            return { ...state, error: false };

        case actions.ADD_SUBJECT_SUCCESS:
            const newState1 = produce(state, draftState => {
                draftState.subjects.unshift(payload)
            })
            return newState1;

        case actions.ADD_SUBJECT_FAIL:
            return { ...state, error: true };

        // Update Subject
        case actions.UPDATE_SUBJECT_START:
            return { ...state, error: false };

        case actions.UPDATE_SUBJECT_SUCCESS:
            const index1 = state.subjects.findIndex(item => item._id === payload._id)
            const newState2 = produce(state, draftState => {
                draftState.subjects[index1] = payload;
            })
            return newState2;

        case actions.UPDATE_SUBJECT_FAIL:
            return { ...state, error: true };

        // Remove Subject
        case actions.REMOVE_SUBJECT_START:
            return { ...state, error: false };

        case actions.REMOVE_SUBJECT_SUCCESS:
            return { ...state, subjects: state.subjects.filter(item => item._id !== payload) };

        case actions.REMOVE_SUBJECT_FAIL:
            return { ...state, error: true };

        default:
            return state;
    }
};

export default subjectReducer;
