import { combineReducers } from "redux";
import authReducer from "./authReducer"
import studentsReducer from "./studentReducer"
import boardsReducer from "./boardReducer"
import classesReducer from "./classReducer"
import streamsReducer from "./streamReducer"
import subjectsReducer from "./subjectReducer"
import chaptersReducer from "./chapterReducer"
import dashboardReducer from "./dashboardReducer"
import contactReducer from "./contactReducer"
import doubtsReducer from "./doubtReducer"
import plansReducer from "./planReducer"
import quizReducer from "./quizReducer"

const rootReducer = combineReducers({
    auth: authReducer,
    students: studentsReducer,
    boards: boardsReducer,
    classes: classesReducer,
    streams: streamsReducer,
    subjects: subjectsReducer,
    chapters: chaptersReducer,
    dashboard: dashboardReducer,
    contacts: contactReducer,
    doubts: doubtsReducer,
    plans: plansReducer,
    quizzes: quizReducer,
})

export default rootReducer