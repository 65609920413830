import { Link, useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { MenuAlt2Icon, XIcon } from "@heroicons/react/outline";
import { MdSchool, MdOutlineMarkEmailUnread } from "react-icons/md";
import { FaBook } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { SiGoogleclassroom } from "react-icons/si";
import { RxDashboard } from "react-icons/rx";
import { RiGitBranchLine } from "react-icons/ri";
import { UsersIcon } from "@heroicons/react/outline";
import Logo from "images/ividya_adobe_express.svg";
import { logout } from "../redux/actions/authAction";
import { useDispatch } from "react-redux";
import { BsChatLeftDots, BsTable } from "react-icons/bs" 

const navigation = [
  { name: "Dashboard", href: "/", icon: RxDashboard },
  { name: "Boards", href: "/Boards", icon: MdSchool },
  { name: "Classes", href: "/classes", icon: SiGoogleclassroom },
  // { name: "Streams", href: "/streams", icon: RiGitBranchLine },
  // { name: "Subjects", href: "/subjects", icon: FaBook },
  // { name: "Chapters", href: "/chapters", icon: IoBookSharp },
  { name: "Students", href: "/students", icon: UsersIcon },
  { name: "Contacts", href: "/contacts", icon: MdOutlineMarkEmailUnread },
  { name: "Doubts", href: "/doubts", icon: BsChatLeftDots },
  { name: "Plans", href: "/plans", icon: BsTable },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-indigo-700">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/workflow-logo-indigo-300-mark-white-text.svg"
                  alt="Workflow"
                />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current
                          ? "bg-indigo-800 text-white"
                          : "text-indigo-100 hover:bg-indigo-600",
                        "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                      )}
                    >
                      <item.icon
                        className="mr-4 flex-shrink-0 h-6 w-6 text-indigo-300"
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-grow pt-5 bg-[#303E45] overflow-y-auto rounded-r-lg">
          <Link to="/" className="flex items-center flex-shrink-0 px-4">
            <img className="h-8 w-auto rounded-md" src={Logo} />
          </Link>
          <div className="mt-5 flex-1 flex flex-col">
            <nav className="flex-1 px-2 pb-4 space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.href === pathname
                      ? "bg-[#379AEF] shadow-md hover:shadow-cyan-500/50 text-white"
                      : "text-white hover:bg-gray-500",
                    "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                  )}
                >
                  <item.icon
                    className="mr-3 flex-shrink-0 h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                  {item.name}
                </Link>
              ))}
              <span
                onClick={() => {
                  dispatch(logout({ callback: () => navigate("/login") }));
                }}
                className="tracking-widest text-sm justify-center md:justify-start flex items-center   cursor-pointer w-full text-white hover:bg-gray-500 px-2 py-2 rounded-md font-medium"
              >
                <FiLogOut className="text-xl mr-3" />
                <span className="md:inline-flex hidden">Log Out </span>
              </span>
            </nav>
          </div>
        </div>
      </div>
      <button
        type="button"
        className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  );
};

export default Sidebar;
