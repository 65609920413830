import * as actions from "../actions/actionTypes";

const initialState = {
    dashboard: {},
    error: false,
};

const dashboardReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // GET Dashboard Data
        case actions.GET_DASHBOARD_COUNTS_START:
            return { ...state, error: false };

        case actions.GET_DASHBOARD_COUNTS_SUCCESS:
            return { ...state, dashboard: payload };

        case actions.GET_DASHBOARD_COUNTS_FAIL:
            return { ...state, error: true };

        default:
            return state;
    }
};

export default dashboardReducer;

