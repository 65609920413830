import * as actions from "../actions/actionTypes";
import produce from "immer";

const initialState = {
    streams: [],
    error: false,
};

const chapterReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // Get all Streams
        case actions.GET_ALL_STREAMS_START:
            return { ...state, error: false };

        case actions.GET_ALL_STREAMS_SUCCESS:
            return { ...state, streams: payload };

        case actions.GET_ALL_STREAMS_FAIL:
            return { ...state, error: true };

        // Add New Stream
        case actions.ADD_STREAM_START:
            return { ...state, error: false };

        case actions.ADD_STREAM_SUCCESS:
            const newState1 = produce(state, draftState => {
                draftState.streams.unshift(payload)
            })
            return newState1;

        case actions.ADD_STREAM_FAIL:
            return { ...state, error: true };

        // Update Stream
        case actions.UPDATE_STREAM_START:
            return { ...state, error: false };

        case actions.UPDATE_STREAM_SUCCESS:
            const index1 = state.streams.findIndex(item => item._id === payload._id)
            const newState2 = produce(state, draftState => {
                draftState.streams[index1] = payload;
            })
            return newState2;

        case actions.UPDATE_STREAM_FAIL:
            return { ...state, error: true };

        // Remove Stream
        case actions.REMOVE_STREAM_START:
            return { ...state, error: false };

        case actions.REMOVE_STREAM_SUCCESS:
            return { ...state, streams: state.streams.filter(item => item._id !== payload) };

        case actions.REMOVE_STREAM_FAIL:
            return { ...state, error: true };

        default:
            return state;
    }
};

export default chapterReducer;
