import * as actions from "../actions/actionTypes";

const initialState = {
    contacts: [],
    error: false,
    pagination: {
        pagesCount: 0,
        memberCount: 0
    }
};

const contactReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // GET ALL CONTACTS
        case actions.GET_ALL_CONTACTS_START:
            return { ...state, error: false };

        case actions.GET_ALL_CONTACTS_SUCCESS:
            return { ...state, contacts: payload.data, pagination: payload.pagination };

        case actions.GET_ALL_CONTACTS_FAIL:
            return { ...state, error: true };

        // Delete Contact
        case actions.REMOVE_CONTACT_START:
            return { ...state, error: false };

        case actions.REMOVE_CONTACT_SUCCESS:
            return { ...state, contacts: state.contacts.filter(contact => contact._id !== payload) };

        case actions.REMOVE_CONTACT_FAIL:
            return { ...state, error: true };

        default:
            return state;
    }
};

export default contactReducer;
