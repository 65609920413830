import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../redux/actions/authAction";
import { GoSignOut } from "react-icons/go";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <nav className="sticky top-0 z-8 flex bg-white justify-between items-center py-3 mb-3 shadow">
      <h1 className="text-black font-medium tracking-wide ml-5 ">
        Welcome to Eshum Technologies
      </h1>
      <div
        className="flex flex-col items-center text-center mr-5 cursor-pointer"
        onClick={() => {
          dispatch(logout({ callback: () => navigate("/login") }));
        }}
      >
        <span>
          <GoSignOut className="w-5 h-5 text-kazari-500" />
          <label className="cursor-pointer text-kazari-500"> LogOut </label>
        </span>
      </div>
    </nav>
  );
};

export default Navbar;
