import * as actions from "../actions/actionTypes";
import produce from "immer";

const initialState = {
    quizzes: [],
    error: false,
};

const quizzesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // Get all Quiz
        case actions.GET_ALL_QUIZ_START:
            return { ...state, error: false };

        case actions.GET_ALL_QUIZ_SUCCESS:
            return { ...state, quizzes: payload };

        case actions.GET_ALL_QUIZ_FAIL:
            return { ...state, error: true };

        // Add New Quiz
        case actions.ADD_QUIZ_START:
            return { ...state, error: false };

        case actions.ADD_QUIZ_SUCCESS:
            const newState1 = produce(state, draftState => {
                draftState.quizzes.unshift(payload)
            })
            return newState1;

        case actions.ADD_QUIZ_FAIL:
            return { ...state, error: true };

        // Update Quiz
        case actions.UPDATE_QUIZ_START:
            return { ...state, error: false };

        case actions.UPDATE_QUIZ_SUCCESS:
            const index1 = state.quizzes.findIndex(item => item._id === payload._id)
            const newState2 = produce(state, draftState => {
                draftState.quizzes[index1] = payload;
            })
            return newState2;

        case actions.UPDATE_QUIZ_FAIL:
            return { ...state, error: true };

        // Remove Quiz
        case actions.REMOVE_QUIZ_START:
            return { ...state, error: false };

        case actions.REMOVE_QUIZ_SUCCESS:
            return { ...state, quizzes: state.quizzes.filter(item => item._id !== payload) };

        case actions.REMOVE_QUIZ_FAIL:
            return { ...state, error: true };

        default:
            return state;
    }
};

export default quizzesReducer;
